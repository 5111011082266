<template>
  <div class="articles">
    <main class="articles__main">
      <div class="articles__content">
        <h2 class="articles__headline">Статьи</h2>
        <ul class="articles__list">
          <li
            class="articles__item"
            v-for="article in articles"
            :id="article.id"
            :key="article.id"
          >
            <h2 class="articles__title">{{ article.title }}</h2>
            <div class="articles__body">
              <img class="articles__image" :src="article.image" alt="" />
              <div class="articles__description">{{ article.description }}</div>
              <a class="articles__more" href="#">Читать далее</a>
            </div>
            <div class="articles__footer"></div>
          </li>
        </ul>
      </div>
      <Sidebar />
    </main>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import Sidebar from "../modules/Sidebar";

  export default {
    name: "Articles",
    components: {
      Sidebar,
    },
    computed: {
      ...mapGetters({
        articles: "getArticles",
      }),
    },
  };
</script>

<style lang="scss" scoped>
  .articles {
    width: 100%;

    &__main {
      @include main;
    }

    &__content {
      @include content;
    }

    &__headline {
      @include headline;
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__item {
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__title {
      font-size: 20px;
      font-weight: 500;
      margin: 0;
      text-transform: uppercase;

      @include mediaquery(md) {
        margin: 0 0 10px;
      }
    }

    &__image {
      width: 100%;
    }

    &__description {
      margin: 20px 0;
    }

    &__more {
      text-decoration: none;
      font-weight: 500;

      &:hover {
        color: $colorBrand;
      }
    }
  }
</style>