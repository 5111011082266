<template>
  <div class="sidebar">
    <div class="sidebar__content">
      <div
        class="sidebar__section"
        v-for="section in sidebar.sections"
        :id="section.id"
        :key="section.id"
      >
        <h2 class="sidebar__title">{{ section.title }}</h2>
        <ul class="sidebar__list">
          <li
            class="sidebar__item"
            v-for="item in section.list"
            :id="item.id"
            :key="item.id"
          >
            <a class="sidebar__link" href="#">{{ item }}</a>
          </li>
        </ul>
      </div>
      <div class="sidebar__cooperation">
        <h2 class="sidebar__title">Сотрудничество</h2>
        <form class="sidebar__cooperation-form">
          <input
            class="sidebar__cooperation-input"
            type="text"
            placeholder="Ваше имя"
          />
          <input
            class="sidebar__cooperation-input"
            type="text"
            placeholder="Ваш телефон"
          />
          <button class="sidebar__cooperation-submit">Отправить</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: "Sidebar",
    computed: {
      ...mapGetters({
        sidebar: "getSidebar",
      }),
    },
    methods: {
      ...mapActions(["fetchSidebar"]),
    },
    created() {
      this.fetchSidebar();
    },
  };
</script>

<style lang="scss">
  .sidebar {
    @include sidebar;

    &__content {
      height: 17px;
      overflow: hidden;

      @include mediaquery(md) {
        height: auto;
        overflow: auto;
      }
    }

    &__section {
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__title {
      @include title;
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__item {
      text-transform: uppercase;
      margin-bottom: 5px;
      font-size: 13px;

      &::before {
        color: $colorBrand;
        content: "\25cf";
        margin-right: 10px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__link {
      text-decoration: none;
      text-transform: uppercase;
      margin: 0 5px;

      &:first-of-type {
        margin-left: 0;
      }

      &:hover {
        color: $colorBrand;
      }
    }

    &__cooperation {
      &-form {
        display: flex;
        flex-direction: column;
      }

      &-input {
        @include input;
        margin-bottom: 10px;
      }

      &-submit {
        @include button;
      }
    }
  }
</style>
