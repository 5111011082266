import {
  createStore
} from 'vuex';

import common from './assets/common';
import header from './modules/header';
import breadcrumbs from './modules/breadcrumbs';
import sidebar from './modules/sidebar';
import articles from './pages/articles';
import catalog from './pages/catalog';
import materials from './pages/materials';
import material from './pages/material';

export const store = createStore({
  modules: {
    common,
    header,
    breadcrumbs,
    sidebar,
    articles,
    catalog,
    materials,
    material
  },
})