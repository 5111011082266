export default {
  state: {
    materials: [],
  },
  getters: {
    getMaterials: state => state.materials,
  },
  mutations: {
    updateMaterials(state) {
      state.materials = [{
          "id": 0,
          "name": "Эмаль ПФ-115",
          "image": "https://livedemo00.template-help.com/wordpress_47711/wp-content/uploads/2013/11/img_5-700x460.jpg",
          description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi ea ut dolor, possimus dolorum debitis aliquid assumenda recusandae quia ex! Ab pariatur similique cumque distinctio ipsam impedit voluptate provident inventore.",
        },
        {
          "id": 2,
          "name": "Эмаль ПФ-133",
          "image": "https://livedemo00.template-help.com/wordpress_47711/wp-content/uploads/2013/11/img_5-700x460.jpg",
          description: "Лак АК-113 по ГОСТ 23832-79 производится из полиакриловой смолы, растворенной в органических растворителях. В состав акрилового лака АК-113 добавляют пластификаторы. Они придают твердость пленке лака. Цвет лака прозрачный.",
        },
        {
          "id": 3,
          "name": "Эмаль МЛ-12",
          "image": "https://livedemo00.template-help.com/wordpress_47711/wp-content/uploads/2013/11/img_5-700x460.jpg",
          description: "Лак АК-113 по ГОСТ 23832-79 производится из полиакриловой смолы, растворенной в органических растворителях. В состав акрилового лака АК-113 добавляют пластификаторы. Они придают твердость пленке лака. Цвет лака прозрачный.",
        },
        {
          "id": 4,
          "name": "Эмаль УР-132",
          "image": "https://livedemo00.template-help.com/wordpress_47711/wp-content/uploads/2013/11/img_5-700x460.jpg",
          description: "Лак АК-113 по ГОСТ 23832-79 производится из полиакриловой смолы, растворенной в органических растворителях. В состав акрилового лака АК-113 добавляют пластификаторы. Они придают твердость пленке лака. Цвет лака прозрачный.",
        },
        {
          "id": 5,
          "name": "Эмаль УР-55",
          "image": "https://livedemo00.template-help.com/wordpress_47711/wp-content/uploads/2013/11/img_5-700x460.jpg",
          description: "Лак АК-113 по ГОСТ 23832-79 производится из полиакриловой смолы, растворенной в органических растворителях. В состав акрилового лака АК-113 добавляют пластификаторы. Они придают твердость пленке лака. Цвет лака прозрачный.",
        },
      ];
    }
  },
  actions: {
    fetchMaterials(context) {
      context.commit("updateMaterials");
    },
  }
}