import router from '@/router';

export default {
  state: {
    sidebar: {},
  },
  getters: {
    getSidebar: state => state.sidebar,
  },
  mutations: {
    updateSidebar(state) {
      let route = router.currentRoute.value.name;

      if (route === 'home') {
        state.sidebar = {
          sections: [{
            id: 0,
            title: 'Новости проекта',
            list: [
              'Мы добавили 200 материалов',
              'Наши успехи',
              'Новые партнеры в проекте',
              'Скоро обновление!',
              'Изменения в политике конфиденциальности',
            ]
          }, ]
        }
      }

      if (route === 'articles' || route === 'catalog') {
        state.sidebar = {
          sections: [{
              id: 0,
              title: 'Категории',
              list: [
                'Строительные материалы',
                'Металлические материалы',
                'Неметаллические материалы',
                'Керамические материалы',
                'Полимерные материалы',
                'Композиционные материалы',
                'Смазочные материалы',
                'Оптические материалы',
                'Абразивные материалы',
              ]
            },
            {
              id: 1,
              title: 'Архив',
              list: [
                'Декабрь 2022',
                'Ноябрь 2022',
                'Октябрь 2022',
                'Сентябрь 2022',
                'Август 2022',
                'Июль 2022',
              ]
            }
          ]
        }
      }

      if (route === 'material' || route === 'materials') {
        state.sidebar = {
          sections: [{
            id: 0,
            title: 'Категории',
            list: [
              'Строительные материалы',
              'Металлические материалы',
              'Неметаллические материалы',
              'Керамические материалы',
              'Полимерные материалы',
              'Композиционные материалы',
              'Смазочные материалы',
              'Оптические материалы',
              'Абразивные материалы',
            ]
          }, ]
        }
      }

      if (route === 'contacts') {
        state.sidebar = {
          sections: [{
            id: 0,
            title: 'Архив',
            list: [
              'Декабрь 2022',
              'Ноябрь 2022',
              'Октябрь 2022',
              'Сентябрь 2022',
              'Август 2022',
              'Июль 2022',
            ]
          }]
        }
      }
    }
  },
  actions: {
    fetchSidebar(context) {
      context.commit("updateSidebar");
    },
  }
}