<template>
  <Preloader />
  <Header />

  <div class="page">
    <div class="container">
      <Breadcrumbs v-if="false" />
      <router-view />
    </div>
  </div>
  <Footer />
</template>

<script>
  import Header from "./layout/modules/Header.vue";
  import Breadcrumbs from "./layout/modules/Breadcrumbs.vue";
  import Preloader from "./layout/modules/Preloader.vue";
  import Footer from "./layout/modules/Footer.vue";

  export default {
    name: "App",
    components: {
      Header,
      Breadcrumbs,
      Preloader,
      Footer,
    },
  };
</script>

<style lang="scss">
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    -webkit-text-size-adjust: 100%;
    height: 100%;
    font-family: "Rubik";
    font-size: 13px;
  }

  body {
    height: 100%;
    min-width: 375px;
    margin: 0;
    background: url(https://livedemo00.template-help.com/wordpress_47711/wp-content/themes/theme47711/images/main_bg.jpg)
      repeat 50% 100%;
    color: $colorDark;
  }

  button,
  input,
  select,
  textarea,
  a {
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    box-shadow: none;
    font-family: "Rubik";
    color: $colorDark;
  }

  .page {
    display: flex;
    align-items: flex-start;
    min-height: 70vh;
    padding: $padding 0 50px;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    @include mediaquery(sm) {
      max-width: 550px;
      padding: 0;
    }

    @include mediaquery(md) {
      max-width: 720px;
    }

    @include mediaquery(lg) {
      max-width: 960px;
    }

    @include mediaquery(xl) {
      max-width: 1280px;
    }

    @include mediaquery(hg) {
      max-width: 1450px;
    }

    @include mediaquery(xh) {
      max-width: 1600px;
    }
  }
</style>
