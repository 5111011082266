<template>
  <header class="header">
    <div class="header__container container">
      <div class="header__wrapper">
        <div class="header__info">
          <span class="header__date">{{ this.headerDate }}</span>
          <span class="header__link">www.matbase.info</span>
        </div>
        <div class="header__base">
          <div class="header__logo">
            <div class="header__logo-text">
              MAT<span class="header__logo-text2">BASE</span>
            </div>
          </div>
          <div class="header__search">
            <input
              class="header__search-input"
              placeholder="Поиск"
              type="text"
            />
            <button class="header__search-button">
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </button>
          </div>
        </div>
        <div class="header__menu">
          <div class="header__navbar" @click="handleNavbarClick">
            <ul class="header__navbar-list">
              <router-link
                v-for="(page, index) in header.pages"
                class="header__navbar-link"
                active-class="header__navbar-link_active"
                exact-active-class=""
                :key="index"
                :to="page.path"
              >
                {{ page.title }}
              </router-link>
            </ul>
          </div>
          <div class="header__toggle" @click="toggleNavbar">
            <span class="header__toggle-line"></span>
            <span class="header__toggle-line"></span>
            <span class="header__toggle-line"></span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "Header",
    data() {
      return {
        headerDate: null,
      };
    },
    computed: {
      ...mapGetters({
        header: "getHeader",
      }),
    },
    methods: {
      getHeaderDate() {
        let date = new Date();
        let months = [
          "Января",
          "Февраля",
          "Марта",
          "Апреля",
          "Мая",
          "Июня",
          "Июля",
          "Августа",
          "Сентября",
          "Октября",
          "Ноября",
          "Декабря",
        ];

        let hours =
          date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let minutes =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

        this.headerDate = `${date.getDate()} ${
          months[date.getMonth()]
        } ${date.getFullYear()} ${hours}:${minutes}`;
      },
      toggleNavbar() {
        document
          .querySelector(".header__navbar")
          .classList.toggle("header__navbar_visible");
      },
      handleNavbarClick(event) {
        if (event.target.closest(".header__navbar-link")) {
          document.querySelector(".header__navbar").className = "header__navbar";
        }
      },
    },
    mounted() {
      this.getHeaderDate();

      setInterval(this.getHeaderDate, 1000);
    },
  };
</script>

<style lang="scss">
  .header {
    width: 100%;
    position: relative;

    &__container {
      display: flex;
      flex-wrap: wrap;
    }

    &__wrapper {
      width: 100%;
      border-top: $border;
      border-bottom: $border;
      padding: 20px 0;
      margin-top: 30px;
      position: relative;
    }

    &__info {
      position: absolute;
      bottom: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &__date,
    &__link {
      text-transform: uppercase;
      font-size: 10px;
      background-color: $colorLight;
      border: $border;
      padding: 5px;
      min-width: 130px;
      text-align: center;
    }

    &__base {
      width: 100%;
      display: flex;
      flex-direction: column;

      @include mediaquery(sm) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }
    }

    &__logo {
      height: 40px;
      display: flex;
      align-items: center;

      &-text {
        font-size: 30px;
        font-weight: 700;
      }
      &-text2 {
        color: $colorBrand;
      }
    }

    &__search {
      display: flex;
      width: 100%;
      margin-top: 20px;

      @include mediaquery(sm) {
        width: 50%;
        margin-top: 0;
      }

      @include mediaquery(md) {
        width: 50%;
        margin-left: 20px;
      }

      @include mediaquery(lg) {
        width: 30%;
      }

      &-input {
        @include input;

        width: calc(100% - 45px);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 0;
      }

      &-button {
        @include button;

        width: 45px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &__navbar {
      display: none;
      position: absolute;
      left: 0;
      top: 80px;
      width: 100%;
      padding: 10px;
      background-color: $colorLight;
      border: $border;
      z-index: $zIndexHeader;

      &_visible {
        display: block;
      }

      @include mediaquery(sm) {
        display: block;
        position: static;
        left: auto;
        top: auto;
        width: auto;
        height: 40px;
      }

      &-list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;

        @include mediaquery(sm) {
          flex-direction: row;
        }
      }

      &-link {
        margin: 5px 0;
        font-size: 20px;
        text-decoration: none;
        text-transform: uppercase;

        @include mediaquery(sm) {
          margin: 0 5px;
          font-size: 16px;
        }

        &:first-of-type {
          margin-left: 0;
        }

        &:hover,
        &_active {
          color: $colorBrand;
        }
      }
    }

    &__toggle {
      position: absolute;
      top: 20px;
      right: 0;
      width: 45px;
      height: 40px;
      padding: 0 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: $border;
      cursor: pointer;

      @include mediaquery(sm) {
        display: none;
      }

      &-line {
        height: 1px;
        background-color: lighten($colorDark, 60%);
        width: 100%;
        margin: 4px 0;
      }
    }
  }
</style>
