export default {
  state: {
    catalog: {},
  },
  getters: {
    getCatalog: state => state.catalog,
  },
  mutations: {
    updateCatalog(state) {
      state.catalog = {
        "categories": [{
            "id": 0,
            "name": "Строительные материалы",
            "icon": 'loupe',
          },
          {
            "id": 1,
            "name": "Металлические материалы",
            "icon": 'loupe',
          },
          {
            "id": 2,
            "name": "Неметаллические материалы",
            "icon": 'loupe',
          },
          {
            "id": 3,
            "name": "Керамические материалы",
            "icon": 'loupe',
          },
          {
            "id": 4,
            "name": "Полимерные материалы",
            "icon": 'loupe',
          },
          {
            "id": 5,
            "name": "Композиционные материалы",
            "icon": 'loupe',
          },
          {
            "id": 6,
            "name": "Смазочные материалы",
            "icon": 'loupe',
          },
          {
            "id": 7,
            "name": "Оптические материалы",
            "icon": 'loupe',
          },
        ]
      }
    }
  },
  actions: {
    fetchCatalog(context) {
      context.commit("updateCatalog");
    },
  }
}