import {
  createRouter,
  createWebHistory
} from 'vue-router'

import Articles from "../layout/pages/Articles.vue";
import Home from "../layout/pages/Home.vue";
import Catalog from "../layout/pages/Catalog.vue";
import About from "../layout/pages/About.vue";
import Contacts from "../layout/pages/Contacts.vue";
import Materials from "../layout/pages/Materials.vue";
import Material from "../layout/pages/Material.vue";
import Nothing from "../layout/pages/Nothing.vue";


const routes = [{
    path: '/',
    redirect: '/home'
  },
  {
    path: '/articles',
    name: 'articles',
    component: Articles
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: Catalog
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts
  },
  {
    path: '/materials',
    name: 'materials',
    component: Materials
  },
  {
    path: '/material',
    name: 'material',
    component: Material
  },
  {
    path: '/:pathMatch(.*)',
    name: 'nothing',
    component: Nothing
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});


router.beforeEach((to, from, next) => {
  const publicPages = [
    '/home',
    '/articles',
    '/about',
    '/catalog',
    '/contacts',
    '/material',
    '/materials',
    '/nothing',
  ];

  if (!publicPages.includes(to.path)) next('/nothing');
  else next();
});

export default router