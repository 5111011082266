<template>
  <div class="contacts">
    <main class="contacts__main">
      <div class="contacts__content">
        <h2 class="contacts__headline">Форма обратной связи</h2>
        <form class="contacts__form">
          <div class="contacts__form-body">
            <div class="contacts__form-column">
              <div class="contacts__form-block">
                <input
                  class="contacts__form-input"
                  type="text"
                  placeholder="Ваше имя"
                />
              </div>
              <div class="contacts__form-block">
                <input
                  class="contacts__form-input"
                  type="text"
                  placeholder="Ваш телефон"
                />
              </div>
              <div class="contacts__form-block">
                <input
                  class="contacts__form-input"
                  type="text"
                  placeholder="Ваша почта"
                />
              </div>
            </div>
            <div class="contacts__form-column">
              <div class="contacts__form-block">
                <textarea
                  class="contacts__form-field"
                  placeholder="Ваше сообщение"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="contacts__form-footer">
            <button class="contacts__form-submit">Отправить</button>
          </div>
        </form>
      </div>
      <Sidebar />
    </main>
  </div>
</template>

<script>
  import Sidebar from "../modules/Sidebar";

  export default {
    name: "Contacts",
    components: {
      Sidebar,
    },
  };
</script>

<style lang="scss" scoped>
  .contacts {
    width: 100%;

    &__main {
      @include main;
    }

    &__content {
      @include content;
    }

    &__headline {
      @include headline;
    }

    &__form {
      &-body {
        display: flex;
        flex-direction: column;

        @include mediaquery(lg) {
          flex-direction: row;
        }
      }

      &-column {
        width: 100%;

        @include mediaquery(lg) {
          width: 50%;

          &:first-of-type {
            margin-right: 10px;
          }
        }
      }

      &-block {
        margin-bottom: 10px;
      }

      &-input {
        @include input;
      }

      &-field {
        @include field;
      }

      &-submit {
        @include button;
      }
    }
  }
</style>