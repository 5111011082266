<template>
  <div class="about">
    <main class="about__main">
      <div class="about__content">
        <h2 class="about__headline">Несколько слов о нас</h2>
        <div class="about__wrapper">
          <img
            class="about__image" :src="require('../assets/images/about-image.jpg')"
            alt=""
          />
          <div class="about__description">
            <p class="about__paragraph">
              MATBASE - это единая база материалов.
            </p>
            <p class="about__paragraph">
              Наш проект задумывался как справочник, объединяющий всю
              необходимую информацию о материалах в одном месте. Теперь нет
              необходимости искать информацию о том или ином материале в разных
              источниках, это уже сделали мы и оформили в удобном виде.
            </p>
            <p class="about__paragraph">
              В настоящий момент в нашей базе 1456 материалов и этот список
              ежедневно пополняется.
            </p>
          </div>
        </div>
      </div>
      <Sidebar />
    </main>
  </div>
</template>

<script>
  import Sidebar from "../modules/Sidebar";

  export default {
    name: "About",
    components: {
      Sidebar,
    },
  };
</script>

<style lang="scss" scoped>
  .about {
    width: 100%;

    &__main {
      @include main;
    }

    &__content {
      @include content;
    }

    &__headline {
      @include headline;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;

      @include mediaquery(lg) {
        flex-direction: row;
        align-items: flex-start;
      }
    }

    &__image {
      width: 100%;
      margin: 0 0 20px;
      border: $border;

      @include mediaquery(lg) {
        width: 50%;
        margin: 0 20px 0 0;
      }
    }

    &__paragraph {
      margin: 10px 0;
      font-size: 16px;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
</style>