<template>
  <div class="home">
    <main class="home__main">
      <div class="home__content">
        <div class="home__sections">
          <section class="home__about">
            <h2 class="home__headline">Наша цель</h2>
            <div class="home__wrapper">
              <img
                class="home__image"
                :src="require('../assets/images/home-image2.jpg')"
                alt=""
              />
              <div class="home__text">
                Нашей целью является объединение всей необходимой информации о
                материалах в одном месте.
              </div>
            </div>
          </section>
          <section class="home__services">
            <h2 class="home__headline">Наш сервис</h2>
            <ul class="home__services-list">
              <li class="home__services-item">
                <img
                  class="home__services-image"
                  :src="require(`../assets/images/home-services-1.svg`)"
                  alt=""
                />
                <span class="home__services-name"
                  >Классификация <br />
                  материалов</span
                >
                <div class="home__services-description">
                  Разделение по категориям для удобства пользователей
                </div>
              </li>
              <li class="home__services-item">
                <img
                  class="home__services-image"
                  :src="require(`../assets/images/home-services-1.svg`)"
                  alt=""
                />
                <span class="home__services-name"
                  >Описание <br />
                  материалов</span
                >
                <div class="home__services-description">
                  Краткая информация, описывающая основные свойства материала
                </div>
              </li>
              <li class="home__services-item">
                <img
                  class="home__services-image"
                  :src="require(`../assets/images/home-services-1.svg`)"
                  alt=""
                />
                <span class="home__services-name"
                  >Область <br />
                  применения</span
                >
                <div class="home__services-description">
                  Краткая информация, описывающая назначение материала
                </div>
              </li>
            </ul>
          </section>
          <section class="home__statistic" v-if="false">
            <h2 class="home__headline">Немного статистики</h2>
            <!-- тут всякие анимированные графики -->
          </section>
          <section class="home__partners" v-if="false">
            <h2 class="home__headline">Наши партнеры</h2>
            <!-- тут список партнеров (карусель???) -->
          </section>
          <section class="home__feedback">
            <h2 class="home__headline">Обратная связь</h2>
            <div class="home__text">
              Если у вас есть предложения по улучшению нашего сервиса или вы
              хотите сотрудничать с нами, отправьте нам сообщение, заполнив
              форму ниже. Мы всегда рады обратной связи!
            </div>
            <form class="home__form">
              <div class="home__form-body">
                <div class="home__form-column">
                  <div class="home__form-block">
                    <input
                      class="home__form-input"
                      type="text"
                      placeholder="Ваше имя"
                    />
                  </div>
                  <div class="home__form-block">
                    <input
                      class="home__form-input"
                      type="text"
                      placeholder="Ваш телефон"
                    />
                  </div>
                  <div class="home__form-block">
                    <input
                      class="home__form-input"
                      type="text"
                      placeholder="Ваша почта"
                    />
                  </div>
                </div>
                <div class="home__form-column">
                  <div class="home__form-block">
                    <textarea
                      class="home__form-field"
                      placeholder="Ваше сообщение"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="home__form-footer">
                <button class="home__form-submit">Отправить</button>
              </div>
            </form>
          </section>
        </div>
      </div>
      <Sidebar />
    </main>
  </div>
</template>

<script>
  import Sidebar from "../modules/Sidebar";

  export default {
    name: "Home",
    components: {
      Sidebar,
    },
  };
</script>

<style lang="scss" scoped>
  .home {
    width: 100%;

    &__main {
      @include main;
    }

    &__content {
      @include content;
    }

    &__headline {
      @include headline;
    }

    &__about {
      border-bottom: $border;
      padding-bottom: 20px;
    }

    &__services {
      padding: 20px 0;
      border-bottom: $border;

      &-list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;

        @include mediaquery(lg) {
          flex-direction: row;
          justify-content: space-between;
        }
      }

      &-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        border: $border;
        padding: 10px 10px 20px;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include mediaquery(lg) {
          width: calc(33.333333% - 15px);
          margin: 0;
        }
      }

      &-name {
        margin: 15px 0 10px;
        font-size: 20px;
        font-weight: 500;
      }

      &-image {
        width: 40%;
      }

      &-description {
        font-size: 16px;
        font-weight: 300;
      }
    }

    &__feedback {
      padding-top: 20px;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;

      @include mediaquery(lg) {
        flex-direction: row;
        align-items: flex-start;
      }
    }

    &__image {
      width: 100%;
      margin: 0 0 20px;
      border: $border;

      @include mediaquery(lg) {
        width: 50%;
        margin: 0 20px 0 0;
      }
    }

    &__text {
      margin: 0 0 20px;
      font-size: 18px;
    }

    &__form {
      &-body {
        display: flex;
        flex-direction: column;

        @include mediaquery(lg) {
          flex-direction: row;
        }
      }

      &-column {
        width: 100%;

        @include mediaquery(lg) {
          width: 50%;

          &:first-of-type {
            margin-right: 10px;
          }
        }
      }

      &-block {
        margin-bottom: 10px;
      }

      &-input {
        @include input;
      }

      &-field {
        @include field;
      }

      &-submit {
        @include button;
      }
    }
  }
</style>