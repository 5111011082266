<template>
  <div class="catalog">
    <main class="catalog__main">
      <div class="catalog__content">
        <h2 class="catalog__headline">Каталог материалов</h2>
        <ul class="catalog__list">
          <li
            class="catalog__item"
            v-for="category in catalog.categories"
            :id="category.id"
            :key="category.id"
            @click="selectCategory"
          >
            <a class="catalog__link" href="#">
              <img
                class="catalog__icon"
                :src="require(`../assets/images/${category.icon}.svg`)"
                alt=""
              />
              <div class="catalog__name">{{ category.name }}</div>
            </a>
          </li>
        </ul>
      </div>
      <Sidebar />
    </main>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import Sidebar from "../modules/Sidebar";

  export default {
    name: "Catalog",
    components: {
      Sidebar,
    },
    computed: {
      ...mapGetters({
        catalog: "getCatalog",
      }),
    },
    methods: {
      ...mapActions(["fetchCatalog"]),
      selectCategory() {
        this.$router.push("/materials");
      },
    },
    created() {
      this.fetchCatalog();
    },
  };
</script>

<style lang="scss" scoped>
  .catalog {
    width: 100%;

    &__main {
      @include main;
    }

    &__content {
      @include content;
    }

    &__headline {
      @include headline;
    }

    &__list {
      margin: -10px;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
    }

    &__item {
      margin: 10px;
      border: $border;
      border-radius: $borderRadius;
      background-color: lighten($colorLight, 100%);
      width: calc(50% - 20px);
      padding: 10px 20px;
      text-align: center;
      cursor: pointer;

      @include mediaquery(md) {
        width: calc(50% - 20px);

        &:last-of-type {
          margin-right: 0;
        }
      }

      @include mediaquery(lg) {
        width: calc(33.333333% - 20px);
      }

      @include mediaquery(xl) {
        width: calc(25% - 20px);
      }

      &:hover {
        border-color: $colorBrand;

        .catalog__name {
          color: $colorBrand;
        }

        .catalog__icon {
          filter: invert(78%) sepia(48%) saturate(2085%) hue-rotate(344deg)
            brightness(100%) contrast(89%);
        }
      }
    }

    &__link {
      text-decoration: none;
      text-align: center;
    }

    &__icon {
      height: 30px;
    }

    &__name {
      margin-top: 10px;
    }
  }
</style>