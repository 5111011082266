import axios from 'axios'

export default {
  state: {
    articles: [{
        id: 0,
        title: 'Заголовок статьи 1',
        image: 'https://livedemo00.template-help.com/wordpress_47711/wp-content/uploads/2013/11/img_1-900x444.jpg',
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
        blandit massa vel mauris sollicitudin dignissim.Phasellus
        ultrices tellus eget ipsum ornare molestie scelerisque eros
        dignissim.Phasellus fringilla hendrerit lectus nec vehicula.
        Pellentesque habitant morbi tristique senectus et netus et
        malesuada fames ac turpis egestas.In faucibus, risus eu
        volutpat pellentesque, massa felis feugiat velit, nec...`
      },
      {
        id: 1,
        title: 'Заголовок статьи 2',
        image: 'https://livedemo00.template-help.com/wordpress_47711/wp-content/uploads/2013/11/img_2-900x444.jpg',
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
        blandit massa vel mauris sollicitudin dignissim.Phasellus
        ultrices tellus eget ipsum ornare molestie scelerisque eros
        dignissim.Phasellus fringilla hendrerit lectus nec vehicula.
        Pellentesque habitant morbi tristique senectus et netus et
        malesuada fames ac turpis egestas.In faucibus, risus eu
        volutpat pellentesque, massa felis feugiat velit, nec...`
      },
      {
        id: 2,
        title: 'Заголовок статьи 3',
        image: 'https://livedemo00.template-help.com/wordpress_47711/wp-content/uploads/2013/11/img_4-900x444.jpg',
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
        blandit massa vel mauris sollicitudin dignissim.Phasellus
        ultrices tellus eget ipsum ornare molestie scelerisque eros
        dignissim.Phasellus fringilla hendrerit lectus nec vehicula.
        Pellentesque habitant morbi tristique senectus et netus et
        malesuada fames ac turpis egestas.In faucibus, risus eu
        volutpat pellentesque, massa felis feugiat velit, nec...`
      },
    ]
  },
  getters: {
    getArticles: state => state.articles,
  },
  mutations: {},
  actions: {
    fetchArticles(context) {
      context.commit("showPreloader");

      axios
        .get("https://api.airwet.app/api/systems/?populate[0]=contract,from_users", this.state.common.headers)
        .then((response) => {
          context.commit('updateSystemsObjects', response.data.data);
          context.commit('updateSystemsNavbar', response.data.data);
          context.commit('updateSystemsApplications');
          context.commit("hidePreloader");
        })
        .catch((error) => context.commit("handleError", error));
    },
  }
}