export default {
  state: {
    breadcrumbs: {
      pages: [{
          name: "home",
          title: "Главная",
          path: "/home",
        },
        {
          name: "articles",
          title: "Статьи",
          path: "/articles",
        },
        {
          name: "contacts",
          title: "Контакты",
          path: "/contacts",
        },
      ],
    },
  },
  getters: {
    getBreadcrumbs: state => state.breadcrumbs,
  },
  mutations: {},
  actions: {}
}