<template>
  <div class="breadcrumbs">
    <ul class="breadcrumbs__list">
      <li class="breadcrumbs__item">
        <a class="breadcrumbs__link" href="#">Главная</a>
      </li>
      <li class="breadcrumbs__item">
        <a class="breadcrumbs__link breadcrumbs__link_active" href="#"
          >Статьи</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "Breadcrumbs",
    computed: {
      ...mapGetters({
        breadcrumbs: "getBreadcrumbs",
      }),
    },
  };
</script>

<style lang="scss">
  .breadcrumbs {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px 15px;
    border: $border;

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
    }

    &__item {
      &:not(:last-of-type) {
        margin-right: 5px;

        &::after {
          content: "/";
          margin-left: 5px;
        }
      }
    }

    &__link {
      text-decoration: none;
      font-weight: 400;
      font-size: 13px;

      &_active {
        color: $colorBrand;
        pointer-events: none;
      }

      &:hover {
        color: $colorBrand;
      }
    }
  }
</style>
