export default {
  state: {
    material: {},
  },
  getters: {
    getMaterial: state => state.material,
  },
  mutations: {
    updateMaterial(state) {
      state.material = {
        name: 'Лак АК-113',
        image: "https://livedemo00.template-help.com/wordpress_47711/wp-content/uploads/2013/11/img_7.jpg",
        usage: "Лак АК-113 применяют для лакирования изделий из черных, цветных металлов и их сплавов. Теплостойкость в характеристиках   лака позволяет наносить его поверхности, работающие при температурах до плюс 150°С.",
        description: "Лак АК-113 по ГОСТ 23832-79 производится из полиакриловой смолы, растворенной в органических растворителях. В состав акрилового лака АК-113 добавляют пластификаторы. Они придают твердость пленке лака. Цвет лака прозрачный.",
        standard: "ГОСТ 23832-79",
        literature: [{
            id: 0,
            name: 'ГОСТ 23832-79. Лаки АК-113 и АК-113Ф. Технические условия',
            href: 'https://internet-law.ru/gosts/gost/31307/'
          }

        ],
        characteristics: [{
            "id": 0,
            "key": "Внешний вид лака",
            "value": "Прозрачная однородная жидкость без механических включений; допускается легкая опалесценция"
          },
          {
            "id": 1,
            "key": " Цвет по йодометрической шкале, мг J2 / 100 см³, не темнее",
            "value": "3"
          },
          {
            "id": 2,
            "key": "Условная вязкость по вискозиметру типа В3 - 246 с диаметром сопла 4 мм при температуре(20± 0,5)°С, с",
            "value": "13 - 17"
          },
          {
            "id": 3,
            "key": "Условная вязкость по вискозиметру типа В3 - 246 с диаметром сопла 2 мм при температуре(20± 0, 5)° С, с",
            "value": "Не нормируется"
          },
          {
            "id": 4,
            "key": "Массовая доля нелетучих веществ, %",
            "value": "6 - 9"
          },
          {
            "id": 5,
            "key": "Кислотное число водной вытяжки, мг КОН, не более",
            "value": "0,1"
          },
          {
            "id": 6,
            "key": "Время высыхания до степени 3 при температуре(20± 0, 5)° С, ч, не более",
            "value": "2"
          },
          {
            "id": 7,
            "key": "Внешний вид пленки лака",
            "value": "Гладкая бесцветная однородная поверхность без оспин и механических включений"
          },
          {
            "id": 8,
            "key": "Твердость пленки по маятниковому прибору типа ТМЛ(маятник А), относительные единицы, не менее",
            "value": "0,45"
          },
          {
            "id": 9,
            "key": "Эластичность пленки при изгибе, мм, не более",
            "value": "1"
          },
          {
            "id": 10,
            "key": "Стойкость пленки к статическому воздействию воды при температуре(20± 2)° С, ч, не менее",
            "value": "4"
          },
          {
            "id": 11,
            "key": "Стойкость пленки к статическому воздействию нефраса при температуре(20± 2)° С, мин., не менее",
            "value": "1"
          }
        ],
        similar: [{
            "id": 0,
            "name": "Эмаль ПФ-115",
            "image": "https://livedemo00.template-help.com/wordpress_47711/wp-content/uploads/2013/11/img_5-700x460.jpg",
          },
          {
            "id": 2,
            "name": "Эмаль ПФ-133",
            "image": "https://livedemo00.template-help.com/wordpress_47711/wp-content/uploads/2013/11/img_5-700x460.jpg",
          },
          {
            "id": 3,
            "name": "Эмаль МЛ-12",
            "image": "https://livedemo00.template-help.com/wordpress_47711/wp-content/uploads/2013/11/img_5-700x460.jpg",
          },
          {
            "id": 4,
            "name": "Эмаль УР-132",
            "image": "https://livedemo00.template-help.com/wordpress_47711/wp-content/uploads/2013/11/img_5-700x460.jpg",
          },
          {
            "id": 5,
            "name": "Эмаль УР-55",
            "image": "https://livedemo00.template-help.com/wordpress_47711/wp-content/uploads/2013/11/img_5-700x460.jpg",
          },
        ]
      }
    }
  },
  actions: {
    fetchMaterial(context) {
      context.commit("updateMaterial");
    },
  }
}