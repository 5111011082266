<template>
  <div class="material">
    <main class="material__main">
      <div class="material__content">
        <h2 class="material__headline">{{ material.name }}</h2>
        <div class="material__card">
          <div class="material__about">
            <div class="material__standard">
              <h3 class="material__standard-title">Стандарт:</h3>
              <div class="material__standard-text">{{ material.standard }}</div>
            </div>
            <div class="material__usage">
              <h3 class="material__usage-title">Область применения:</h3>
              <div class="material__usage-text">{{ material.usage }}</div>
            </div>
            <div class="material__description">
              <h3 class="material__description-title">Описание:</h3>
              <div class="material__description-text">
                {{ material.description }}
              </div>
            </div>
          </div>
          <img class="material__image" :src="material.image" alt="" />
        </div>
        <div class="material__characteristics">
          <h3 class="material__characteristics-title">
            Характеристики материала:
          </h3>
          <table class="material__table">
            <thead class="material__table-thead">
              <tr class="material__table-row">
                <th class="material__table-th">Характеристика</th>
                <th class="material__table-th">Значение</th>
              </tr>
            </thead>
            <tbody class="material__table-tbody">
              <tr
                class="material__table-row"
                v-for="characteristic in material.characteristics"
                :id="characteristic.id"
                :key="characteristic.id"
                @click="selectSystem($event, row.id)"
              >
                <td class="material__table-td">
                  {{ characteristic.key }}
                </td>
                <td class="material__table-td">
                  {{ characteristic.value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="material__literature">
          <h3 class="material__literature-title">Литература:</h3>
          <ul class="material__literature-list">
            <li
              class="material__literature-item"
              v-for="literature in material.literature"
              :id="literature.id"
              :key="literature.id"
            >
              <a
                class="material__literature-link"
                :href="literature.href"
                target="_blank"
                >{{ literature.name }}</a
              >
            </li>
          </ul>
        </div>
        <div class="material__similar">
          <h3 class="material__similar-title">Похожие материалы</h3>
          <ul class="material__similar-list">
            <li
              class="material__similar-item"
              v-for="similar in material.similar"
              :id="similar.id"
              :key="similar.id"
            >
              <a class="material__similar-link" href="#">
                <img
                  class="material__similar-image"
                  :src="similar.image"
                  alt=""
                />
                <div class="material__similar-name">{{ similar.name }}</div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Sidebar />
    </main>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import Sidebar from "../modules/Sidebar";

  export default {
    name: "Material",
    components: {
      Sidebar,
    },
    computed: {
      ...mapGetters({
        material: "getMaterial",
      }),
    },
    methods: {
      ...mapActions(["fetchMaterial"]),
    },
    created() {
      this.fetchMaterial();
    },
  };
</script>

<style lang="scss" scoped>
  .material {
    width: 100%;

    &__main {
      @include main;
    }

    &__content {
      @include content;
    }

    &__headline {
      @include headline;
    }

    &__card {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-bottom: 20px;
    }

    &__about {
      display: flex;
      flex-direction: column;
      order: 2;
      margin-top: 10px;

      @include mediaquery(lg) {
        width: calc(50% - 20px);
        order: 1;
        margin-top: 0;
        margin-right: 20px;
      }
    }

    &__standard {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &-title {
        margin: 0;
      }

      &-text {
        margin-left: 10px;
      }
    }

    &__usage {
      margin-bottom: 10px;

      &-title {
        margin: 0 0 5px;
      }
    }

    &__description {
      &-title {
        margin: 0 0 5px;
      }
    }

    &__image {
      width: 100%;
      order: 1;
      border: $border;
      border-radius: $borderRadius;

      @include mediaquery(lg) {
        width: 50%;
        order: 2;
      }
    }

    &__characteristics {
      &-title {
        margin: 0 0 5px;
      }
    }

    &__literature {
      margin: 20px 0;

      &-title {
        margin: 0 0 10px;
      }

      &-list {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      &-item {
        text-transform: uppercase;
        margin-bottom: 5px;
        font-size: 13px;

        &::before {
          color: $colorBrand;
          content: "\25cf";
          margin-right: 10px;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &-link {
        text-decoration: none;
        text-transform: uppercase;

        &:first-of-type {
          margin-left: 0;
        }

        &:hover {
          color: $colorBrand;
        }
      }
    }

    &__table {
      @include table;
    }

    &__similar {
      margin-top: 30px;

      &-title {
        margin: 0 0 5px;
      }

      &-list {
        margin: -10px;
        margin-top: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @include mediaquery(sm) {
          flex-direction: row;
        }
      }

      &-item {
        width: calc(100% - 20px);
        margin: 10px;

        @include mediaquery(sm) {
          width: calc(50% - 20px);
        }

        @include mediaquery(md) {
          width: calc(100% - 20px);
        }

        @include mediaquery(lg) {
          width: calc(33.333333% - 20px);
        }

        @include mediaquery(xl) {
          width: calc(25% - 20px);
        }
      }

      &-link {
        text-decoration: none;

        &:hover {
          .material__similar-name {
            color: $colorBrand;
          }
        }
      }

      &-image {
        width: 100%;
        border: $border;
        border-radius: $borderRadius;
      }

      &-name {
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }
</style>