<template>
  <div class="materials">
    <main class="materials__main">
      <div class="materials__content">
        <h2 class="materials__headline">Материалы</h2>
        <ul class="materials__list">
          <li
            class="materials__item"
            v-for="material in materials"
            :id="material.id"
            :key="material.id"
            @click="selectMaterial"
          >
            <img class="materials__image" :src="material.image" alt="" />
            <div class="materials__desc">
              <h2 class="materials__title">{{ material.name }}</h2>
              <div class="materials__description">
                {{ material.description }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <Sidebar />
    </main>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import Sidebar from "../modules/Sidebar";

  export default {
    name: "Materials",
    components: {
      Sidebar,
    },
    computed: {
      ...mapGetters({
        materials: "getMaterials",
      }),
    },
    methods: {
      ...mapActions(["fetchMaterials"]),
      selectMaterial() {
        this.$router.push("/material");
      },
    },
    created() {
      this.fetchMaterials();
    },
  };
</script>

<style lang="scss" scoped>
  .materials {
    width: 100%;

    &__main {
      @include main;
    }

    &__content {
      @include content;
    }

    &__headline {
      @include headline;
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__item {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      border: $border;
      padding: 15px;
      border-radius: $borderRadius;
      cursor: pointer;

      @include mediaquery(lg) {
        flex-direction: row;
      }

      &:hover {
        border-color: $colorBrand;

        .materials__title {
          color: $colorBrand;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__image {
      width: 100%;
      border: $border;

      @include mediaquery(lg) {
        width: 200px;
      }
    }

    &__desc {
      margin-top: 10px;

      @include mediaquery(lg) {
        margin-left: 20px;
        margin-top: 0;
      }
    }

    &__title {
      font-size: 20px;
      font-weight: 500;
      margin: 0;
      text-transform: uppercase;

      @include mediaquery(md) {
        margin: 0 0 10px;
      }
    }

    &__description {
      font-size: 14px;
      display: none;

      @include mediaquery(md) {
        display: block;
      }
    }
  }
</style>