export default {
  state: {
    header: {
      id: null,
      page: '/',
      pages: [{
          name: "home",
          title: "Главная",
          path: "/home",
        },
        {
          name: "catalog",
          title: "Каталог",
          path: "/catalog",
        },
        {
          name: "articles",
          title: "Статьи",
          path: "/articles",
        },
        {
          name: "about",
          title: "О нас",
          path: "/about",
        },
        {
          name: "contacts",
          title: "Контакты",
          path: "/contacts",
        },
      ],
    },
  },
  getters: {
    getHeader: state => state.header,
  },
  mutations: {},
  actions: {}
}